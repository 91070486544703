import $ from 'jquery';
import Cookies from 'js-cookie';
import './form.js';
import './app.js';

window.jQuery = window.$ = $;
$(function() {
    $.fn.extend({
        toggleText: function(a, b){
            return this.text(this.text() == b ? a : b);
        }
    });

    if(Cookies.get('privacy-policy') !== "true") {
        // Set cookies
        $("#cookies-modal").show();
        // Option to accept cookies
        $("#cookies-button").on("click", function() {
            Cookies.set('privacy-policy', "true");
            $("#cookies-modal").hide();
        });
    }
    if($(this).scrollTop() > 200) {
        $("header").addClass("scrolled");
        $("nav.primary").addClass("scrolled");
        $(".mobile-nav").addClass("scrolled");
    }
    $(window).on("scroll", function() {
        // Make the navbar solid background after scrolling down the page
        if(($(window).width() < 768 && $(this).scrollTop() > 200) || ($(window).width() > 769 && $(this).scrollTop() > 550)) {
            // Only shift the navbar on mobile
            $("header").addClass("scrolled");
            $("nav.primary").addClass("scrolled");
            $(".mobile-nav").addClass("scrolled");
            $(".overlay").addClass("scrolled");
            $(".disclaimer").css("background", "#000101");
            $(".main-nav").css("background", "linear-gradient(90deg, #003940 0%, #006062 49.94%, #003840 99.45%)");
        } else {
            $("header").removeClass("scrolled");
            $("nav.primary").removeClass("scrolled");
            $(".mobile-nav").removeClass("scrolled");
            $(".overlay").removeClass("scrolled");
            $(".disclaimer").css("background", "");
            $(".main-nav").css("background", "");
        }
    });
    $(".mobile-nav").on("click", function() {
        $("#nav").toggleClass("opened");
        $(".mobile-nav").toggleClass("opened");
        var color = $('header').attr('class');
        $(".overlay").addClass(color);
        $(".overlay").show();
        if($(this).text() === "") {
            $(".mobile-nav").text("✕");
            $(".overlay").show();
        } else {
            $(".mobile-nav").text("");
            $(".overlay").hide();
        }
    });
    $(".nav-item").on("click", function() {
        $(this).toggleClass('opened');
    });
    $(".overlay, a.return, a.leaving-continue").on("click", function() {
        $(".modal, aside, .overlay").hide();
        $(".mobile-nav, #nav").removeClass("opened");
        $(".mobile-nav").text("");
    });

    $(".modal-leaving").on("click", function(){
        var newURL = $(this).data("href");
        $(".overlay, #leaving").show();
        $("body").addClass("modal-active");
        $("#leaving").find("a.button").attr("href", newURL)
    });

    $(".tab").on("click", function(){
        $(this).find(".tab-content").parent().toggleClass('opened');
    })
});