import Pristine from "pristinejs";
var url = window.location.pathname;
var filename = url.substring(url.lastIndexOf('/')+1);

/* globals Pristine */
if(filename === "sign-up" || filename === "sign-up.html") {
    window.addEventListener("load", function () {
        // FORM VALIDATOR
        var form = document.getElementById("sign_up");
        var pristine = new Pristine(form);
        var specialty = document.getElementById("specialty");
        var request = document.getElementById("request");
        var phone = document.getElementById("phone-number");

        function isValid(p) {
            var phoneRe = /^[(]{0,1}[0-9]{3}[)]{0,1}[-\s\.]{0,1}[0-9]{3}[-\s\.]{0,1}[0-9]{4}$/;
            var digits = p.toString().replace(/\D/g, "");
            return phoneRe.test(digits);
        }

        pristine.addValidator(phone, function(value) {
            console.log(value)
            if($("#phonePref").is(':checked') && !isValid(value)) {
                return false;
            }
            return true;
        }, "To be contacted by phone, please enter your phone number.", 2, false);

        form.addEventListener("submit", function (e) {
            e.preventDefault();
            var valid = pristine.validate();
            
            if (valid) {
                const mapping = {
                    email: "SRV_Email_Address__c",
                    'phone-number': "SRV_Phone__c",
                    name: "SRV_First_Name__c",
                    lastName: "SRV_Last_Name__c",
                    specialty: "SRV_Specialty__c",
                    institution: "SRV_Institution__c",
                    zipCode: "SRV_Zip_Code__c",
                    request: "SRV_Topic__c",
                    npi: "SRV_NPI__c",
                    phonePref: "SRV_Contact_by_Phone__c",
                    emailPreferred: "SRV_Contact_by_Email__c"
                };
                
                const elements = e.target.elements;
                const cForm = {
                    SRV_Web_Form_Type__c: "Request a Rep",
                    SRV_Rep_Type__c: "SOC"
                };

                for (let element of elements) {
                    const key = element.id;
                    console.log("key: " + key);
                    if (key in mapping) {
                      if (element.type == 'checkbox') {
                          cForm[mapping[key]] = element.checked ? true : false;
                      } else if (key == "specialty" && element.value == "Select your specialty..." || key == "request" && element.value == "Select a topic...") {
                        cForm[mapping[key]] = "";
                      } else {
                        cForm[mapping[key]] = element.value;
                      }
                    }
                  }
        
                console.log("form", cForm);
                SRV_TDM.saveWebForm(cForm, function (response, event) {
                    if (event.status) {
                        if (response.status === 200) {
                            console.log("success...");
                            window.location.href = "./thank-you";
                        } else {
                            alert(response.msg);
                        }
                    }
                });
            }
        });        
        
    
        specialty.addEventListener("change", function () {
            if (specialty.value !== "") {
                specialty.classList.add("selected");
            } else {
                specialty.classList.remove("selected");
            }
        });
    });    
}